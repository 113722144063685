<template>
  <div>
    <b-overlay
      :show="isLoading"
      rounded
      opacity="0.6"
      spinner-variant="primary"
      spinner-mediam
      >
      <b-row>
        <b-col class="text-right">
          <b-button
            to="/shipping-zones/create"
            size="sm"
            variant="success"
            class="mb-1"
          >
            <feather-icon icon="PlusSquareIcon" />
            {{$t('Create')}}
          </b-button>
        </b-col>
      </b-row>
      <b-row class="match-height" v-if="!isLoading">
        <template v-if="shippingZones && Object.keys(shippingZones.data).length > 0">
        <template v-for="(data, index) in shippingZones.data">
          <transition
          :name="$store.state.appConfig.layout.routerTransition"
          mode="out-in" :key="index"
          >
          <b-col md="12">
            <b-card no-body>
              <b-row>
                <b-col md="9" cols="8">
                  <b-card-body>
                    <b-card-title>{{data.name}}</b-card-title>
                    <b-row class="m-0">
                      #{{data.id}}
                      <!-- <b-badge class="d-block ml-1" :variant="data.is_active ? 'light-success' : 'light-danger'">
                        {{data.is_active ? $t('Active') : $t('Not Active')}}
                      </b-badge> -->
                    </b-row>
                  </b-card-body>
                </b-col>
                <b-col md="3" cols="4" align-self="center" class="text-center">
                  <b-button
                    variant="outline-primary"
                    class="btn-icon mr-1"
                    :to="`/shipping-zones/${data.id}`"
                  >
                    <feather-icon icon="EditIcon" />
                  </b-button>
                  <b-button
                    variant="outline-danger"
                    class="btn-icon"
                    v-on:click="deleteShippingZone(data)"
                  >
                    <feather-icon icon="XCircleIcon" />
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
          </transition>
        </template>
        </template>
        <template v-else>
          <b-col>
            <b-card class="text-center">
                <span>{{$t('content not found')}}.</span>
            </b-card>
          </b-col>
        </template>
      </b-row>
      <pagination v-if="shippingZones" :limit="7" :data="shippingZones" @pagination-change-page="getShippingZones"></pagination>
    </b-overlay>
  </div>
</template>

<script>
import useJwt from '@/auth/useJwt'

export default {
  name: 'ShippingZones',
  data() {
    return {
      shippingZones: null,
      isLoading: false,
    }
  },
  mounted() {
    this.getShippingZones();
  },
  methods: {
    getShippingZones(page = 1){
      this.isLoading = true
      useJwt.get('/shipping-zones/get-all-with-pagination',{params: {
          page: page,
      }})
      .then((response) => {
        // console.log(response.data.data)
        this.shippingZones = response.data.data
        console.log(this.shippingZones)
        this.isLoading = false
      })
      .catch(response => {
        // console.log(response);
      });
    },
    deleteShippingZone(shippingZone) {
        if(confirm("Do you really want to delete zone "+shippingZone.name+"?")){
            this.isLoading = true
            useJwt.post('/shipping-zones/destroy',shippingZone)
            .then((response) => {
              this.getShippingZones()
              this.isLoading = false
            })
            .catch(response => {
              // console.log(response);
            });
        }
    }
  }
}
</script>
